import moment from 'moment'
// import _ from 'lodash-core'

export default {
  methods: {
    /**
     * Shorten name to be an initial so it can be used for avatar
     * @param {String} name
     * @returns {String} Initial name
     */
    getInitialName(name, participantType) {
      if (name) {
        if (name.split(' ').length > 2)
          return (
            name.split(' ')[0] +
            ' ' +
            name.split(' ')[name.split(' ').length - 1]
          )
        else return name
      } else if (participantType === 'bot') {
        return 'Bot'
      } else {
        return ''
      }
    },
    /**
     * Define icon based on channel slug
     * @param {String} channel Channel slug
     * @returns {String} Icon class name
     */
    getChannelIcon(channel = '') {
      if (channel) {
        switch (channel.toLowerCase()) {
          case 'whatsapp':
            return 'lab la-whatsapp'
          case 'wa':
            return 'lab la-whatsapp'
          case 'wa_cloud':
            return 'lab la-whatsapp'
          case 'qontak':
            return 'las la-comments'
          case 'email':
            return 'las la-envelope'
          case 'qiscus':
            return 'las la-comments'
          case 'telegram':
            return 'lab la-telegram'
          case 'fb':
            return 'lab la-facebook-messenger'
          case 'livechat_dot_com':
            return 'las la-comment-alt'
          case 'ig':
            return 'lab la-instagram'
          case 'ig_comment':
            return 'lab la-instagram'
          case 'twitter':
            return 'lab la-twitter'
          case 'line':
            return 'lab la-line'
          case 'website':
            return 'las la-laptop'
          case 'app_chat':
            return 'las la-comments'
        }
      } else {
        return 'las la-exclamation-circle'
      }
    },
    /**
     * Define channel name based on channel slug
     * @param {String} channel Channel slug
     * @returns {String} Channel name
     */
    getChannelName(channel) {
      switch (channel) {
        case 'wa':
          return 'WhatsApp'
        case 'wa_cloud':
          return 'WhatsApp'
        case 'ig':
          return 'Instagram'
        case 'ig_comment':
          return 'Instagram Comment'
        case 'telegram':
          return 'Telegram'
        case 'fb':
          return 'Facebook Messenger'
        case 'livechat_dot_com':
          return 'Livechat.com'
        case 'email':
          return 'Email'
        case 'twitter':
          return 'Twitter'
        case 'line':
          return 'Line'
        case 'web_chat':
          return 'Webchat'
        case 'app_chat':
          return 'Mobile chat'
        case 'qontak':
          return 'Qontak'
        case 'tokopedia_chat':
          return 'Tokopedia'
        case 'shopee':
          return 'Shopee'
        default:
          return channel
      }
    },
    /**
     * Define message status icon
     * @param {String} status Message status slug
     * @returns {String} Icon class name
     */
    getStatusIcon(status) {
      switch (status) {
        case 'read':
          return 'la-check-double text-primary'
        case 'delivered':
          return 'la-check-double'
        case 'sent':
          return 'la-check'
        case 'pending':
          return 'la-clock'
        case 'failed':
          return 'la-times text-danger font-weight-bold'
        case 'created':
          return 'la-check'
      }
    },
    /**
     * Define message status tooltip
     * @param {String} status Message status slug
     * @returns {String} Status tooltip
     */
    getStatusTooltips(status) {
      switch (status) {
        case 'read':
          return 'Read'
        case 'sent':
          return 'Sent'
        case 'delivered':
          return 'Delivered'
        case 'pending':
          return 'Processing'
        case 'failed':
          return 'Failed'
        case 'created':
          return 'Sent'
        default:
          return status ? status[0].toUpperCase() + status.substring(1) : ''
      }
    },
    /**
     * Group the conversation by day, sender, and group image preview
     * @param {Array} conversation Array of message
     * @param {String} avatarFallback Image source to handle if there is no avatar on the conversation
     * @returns {Array} Grouped conversation
     */
    groupingConversation(conversation, avatarFallback = '') {
      try {
        // const formatted = _.sortBy(conversation, (c) => c.created_at)
        // move this sorting to conversation.js store mutation UPDATE_CONVERSATION to fix bug bubble chat not pushed to the latest message if client timezone not sync with global
        const formatted = conversation
        const consecutiveImageMsg = [] // to collect grid images
        for (const x in formatted) {
          // Grouping By Day
          if (x < 1) {
            formatted[x].displayDivider = true
            formatted[x].displayName = true
          } else if (x > 0) {
            if (
              moment(formatted[x].created_at).format('DDMMYYYY') !==
              moment(formatted[x - 1].created_at).format('DDMMYYYY')
            )
              formatted[x].displayDivider = true
            else formatted[x].displayDivider = false

            // On same user in username display
            if (formatted[x].displayDivider) {
              if (formatted[x].sender.id === formatted[x - 1].sender.id) {
                formatted[x].displayName = true
              } else {
                formatted[x].displayName = true
              }
            } else if (!formatted[x].displayDivider) {
              if (formatted[x].sender.id === formatted[x - 1].sender.id) {
                formatted[x].displayName = false
              } else {
                formatted[x].displayName = true
              }
            }
          }

          // Check for grouping chat on same user
          if (x < formatted.length - 1) {
            if (
              formatted[x].sender.id === formatted[parseInt(x) + 1].sender.id
            ) {
              if (formatted[parseInt(x) + 1].displayDivider)
                formatted[x].displayTime = true
              else formatted[x].displayTime = false
            } else {
              formatted[x].displayTime = true
            }
          } else {
            formatted[x].displayTime = true
          }

          formatted[x].gridType = null
          formatted[x].gridImages = []

          // Create list for image with 4 or more pics
          if (formatted[x].message.type === 'image') {
            if (consecutiveImageMsg.length > 0) {
              const currentIdx =
                consecutiveImageMsg[consecutiveImageMsg.length - 1]
              const isConsecutive =
                parseInt(currentIdx[currentIdx.length - 1]) === parseInt(x - 1)
              const isSameOnMinute =
                moment(formatted[x].created_at).format('DDMMYYYYHHmm') ===
                moment(formatted[parseInt(x) - 1].created_at).format(
                  'DDMMYYYYHHmm'
                )
              const isSameSender =
                formatted[x].participant_type ===
                formatted[x - 1].participant_type
              const isCurrentHasNoCaption = !formatted[x].message.text
              if (
                isConsecutive &&
                isSameOnMinute &&
                isSameSender &&
                isCurrentHasNoCaption
              ) {
                consecutiveImageMsg[consecutiveImageMsg.length - 1].push(x)
              } else {
                formatted[x].gridType = null
                consecutiveImageMsg.push([])
                consecutiveImageMsg[consecutiveImageMsg.length - 1].push(x)
              }
            } else {
              consecutiveImageMsg.push([])
              consecutiveImageMsg[consecutiveImageMsg.length - 1].push(x)
            }
          }

          // Grouping By Day (Detail)
          if (
            moment(formatted[x].created_at).format('DDMMYYYY') ===
            moment().format('DDMMYYYY')
          )
            formatted[x].dateCondition = 'Today'
          else if (
            moment(formatted[x].created_at).format('DDMMYYYY') ===
            moment().subtract(1, 'days').format('DDMMYYYY')
          )
            formatted[x].dateCondition = 'Yesterday'
          else
            formatted[x].dateCondition = moment(formatted[x].created_at).format(
              'dddd, DD MMMM YYYY'
            )

          // handle if there is no avatar on conversation data
          if (
            avatarFallback &&
            !formatted[x].sender.avatar &&
            formatted[x].sender.type === 'Models::Contact'
          ) {
            this.$store.commit('conversation/UPDATE_CONVERSATION_AVATAR', {
              avatar: avatarFallback,
              index: x,
            })
          }
        }

        // loop consecutive image to determine if messages are converted to grid or not
        consecutiveImageMsg.forEach((consecutive) => {
          if (consecutive.length > 3) {
            consecutive.forEach((consIdx, index) => {
              formatted[consIdx].gridType = index === 0 ? 'grid' : 'hidden'
              formatted[parseInt(consecutive[0])].gridImages.push(
                formatted[consIdx].message?.image
                  ? formatted[consIdx].message?.image?.large
                  : formatted[consIdx].file.url
              )
            })
          }
        })

        return formatted
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('groupingConversation:', error)
      }
    },
    /**
     * Handle fallback image for conversation avatar
     * @param {*} avatar Image url
     * @param {*} participant Participant type
     * @returns Avatar image
     */
    mappingAvatar(avatar, participant) {
      let url = ''
      if (avatar === null)
        avatar = require('assets/images/graphics/qi-user.svg')
      if (participant !== 'bot') {
        if (typeof avatar !== 'object') url = avatar
        else if (avatar.url) url = avatar.url
        else url = require('assets/images/graphics/qi-user.svg')
      }
      return url
    },
    /**
     * Define avatar background
     * @param {Object} sender Sender data, at least consist of id and name
     * @returns Color
     */
    defineAvatarBackground(sender) {
      if (sender.id === this.$auth.user.id) {
        return '#0274f5'
      } else {
        const name = sender.name || 'User'
        let hash = 0

        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash)
        }
        const h = hash % 360
        return 'hsl(' + h + ', ' + 60 + '%, ' + 80 + '%)'
      }
    },
    /**
     * Determine whether message status should be shown or not
     * @param {Object} conversation Message data, at least consist of participant type and status
     */
    determineShowMessageStatus(conversation) {
      return (
        (conversation.participant_type === 'agent' ||
          conversation.participant_type === 'bot') &&
        conversation.status !== 'pending' &&
        conversation.message.type !== 'calls'
      )
    },
  },
}
